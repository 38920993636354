@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Kawi:wght@500&family=Roboto&display=swap");
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.carousel-indicators {
  display: none !important;
}
.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}
.carousel-caption {
  top: 20% !important;
  left: 12%;
}
.icons {
  width: 65px;
  height: 65px;
  padding: 10px;
  background-color: #fcbef0;
  border-radius: 25px;
}
.bg-footer {
  background-color: #001029;
}
.foot-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.bg-footer ul {
  list-style-type: none;
  padding-left: 4px;
}
.bg-footer li a {
  text-decoration: none;
  color: rgba(245, 245, 245, 0.9);
}
.bg-footer li a:hover {
  color: rgba(245, 245, 245, 1);
}
.bg-about {
  background-color: rgba(224, 255, 255, 0.4);
}
.service-icon {
  width: 60px;
}
.bg-signin {
  background: url("./assets/bg.png");
  height: 100vh;
}
.bg-sign {
  background-color: #275d92;
}
.sign-icon {
  width: 20px;
  height: 20px;
}
.pro-pic {
  width: 65px;
}
.sign-text {
  margin-top: -50% !important;
}
.slide {
  position: relative;
}
.career {
  width: 40%;
  position: absolute;
  left: 55%;
  top: 20%;
  /* background-color: rgba(220, 216, 202, 0.7); */
  padding: 15px 35px 35px;
  border-radius: 8px;
  color: cornsilk;
}
.bg1 {
  display: block;
  height: 64px;
  z-index: 10;
  pointer-events: none;
  overflow: hidden;
  margin-top: -64px;
  background-color: #001029;
  -webkit-mask-image: url("./assets/wave.svg");
  mask-size: 1940px;
  -webkit-mask-size: 1940px;
  position: absolute;
  left: -15px;
  right: 0;
}
.certificate {
  background-color: rgba(242, 239, 232, 0.4);
}
.payment {
  background: url("./assets/bg1.jpg");
}
.nav-icon {
  width: 22px;
}
.pic-icon {
  width: 32px;
}
.notif {
  position: absolute;
  right: 4.2%;
  top: 25%;
}
.notification:hover .notif {
  background-color: green !important;
}
.sidebar {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  height: 612px;
  top: 95px;
}
.profile-pic {
  width: 75px;
}
.profile {
  /* background-color: #b9bdc1; */
  border-bottom: 1px solid #b9bdc1;
}
.side-nav a {
  color: #5e5e5e;
  font-weight: 500;
}
.bg-dash {
  background-color: rgba(245, 245, 245, 0.8);
}
.sub-expir {
  background-color: rgba(255, 243, 204, 0.5);
  border: 1px solid rgba(243, 231, 193, 0.8);
}
.sub-card {
  width: 65px;
}
.dash-col {
  border: 1px solid #e2e9e6;
}
.rewards {
  width: 45px;
}
.small {
  font-size: 12px !important;
  padding-top: -15px !important;
  font-weight: 500;
  color: rgb(70, 70, 70);
}
.small span {
  font-weight: normal;
  color: blue;
}
.desrciption {
  font-size: 11px !important;
  padding-top: 4px !important;
  font-weight: 500;
  line-height: 14px;
  padding-bottom: 6px;
  color: rgb(70, 70, 70);
}
.quiz p {
  margin-bottom: -5px !important;
}
.quiz h2 {
  margin-bottom: -8px !important;
}
.sub-pic {
  width: 38px;
  height: 38px;
}
.time {
  font-size: 12px !important;
}
.course-img {
  width: 250px !important;
}
.course-title {
  font-size: 13.5px !important;
  line-height: 18px;
  font-weight: 500;
  padding: 5px 3px;
}
.view-all {
  font-size: 12px;
  color: #275d92;
  cursor: pointer;
}
.course-details {
  font-size: 11px;
}
.course-details i {
  color: gray;
}
.buttn {
  font-size: 13px;
  font-weight: 700;
  background-color: orange;
  padding: 3px 10px;
  border-radius: 5px;
}
.likes {
  cursor: pointer;
}
.credits {
  font-size: 16px;
}
.credits sup {
  margin-left: -4px;
  color: green;
}
.quiz-det p {
  font-size: 13px;
  margin-bottom: -5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quiz-det p span {
  font-size: 15px;
}
.quiz-det small {
  font-size: 10px;
  color: rgb(163, 163, 163);
}

.urname td {
  font-size: 16px;
  font-weight: 700;
  color: rgb(177, 0, 177);
  border-top: 1px dotted rgba(185, 189, 193, 0.3);
  margin-top: 10px;
}
.qtn {
  font-size: 12px;
  color: #5e5e5e;
}
.clk {
  font-size: 18px;
}
.rules ul li {
  font-size: 13px;
}
.user-det img {
  width: 85px;
}
.user-det h6 {
  margin-bottom: -3.8px;
}
.user-det .bio {
  font-size: 13.5px;
  margin-bottom: 2px;
}
.user-det .bio span {
  width: 19% !important;
}
.abt {
  font-size: 13.5px;
  margin-bottom: -2px;
  line-height: 18px;
}
.edit {
  position: absolute;
  right: 3%;
}
.nav-pills {
  background-color: #e2f3fc;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #019ff8 !important;
  border-radius: 3px;
}
.nav-pills .nav-item {
  width: 33.3% !important;
  text-align: center;
}
.contact {
  line-height: 16px;
}
.price small {
  font-size: 18px;
}
.price span {
  margin-top: -5px;
}
.plan {
  color: rgb(0, 0, 72);
}
.plan-icon {
  width: 65px;
  border: 1px solid rgba(206, 220, 228, 0.5);
  padding: 18px;
  border-radius: 50%;
  background-color: rgba(227, 243, 252, 0.5);
}
.recommend {
  padding: 4px 80px;
  border-radius: 5px 5px 0 0;
}
